font-face {
    font-family: 'Nunito';
    font-weight: 200;
    font-style: normal;
    src: url('../fonts/nunito-extralight.eot');
    src: url('../fonts/nunito-extralight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-extralight.woff2') format('woff2'),
        url('../fonts/nunito-extralight.woff') format('woff'),
        url('../fonts/nunito-extralight.ttf') format('truetype'),
        url('../fonts/nunito-extralight.svg#nunitoextralight') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 200;
    font-style: italic;
    src: url('../fonts/nunito-extralightitalic.eot');
    src: url('../fonts/nunito-extralightitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-extralightitalic.woff2') format('woff2'),
        url('../fonts/nunito-extralightitalic.woff') format('woff'),
        url('../fonts/nunito-extralightitalic.ttf') format('truetype'),
        url('../fonts/nunito-extralightitalic.svg#nunitoextralight_italic') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/nunito-light.eot');
    src: url('../fonts/nunito-light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-light.woff2') format('woff2'),
        url('../fonts/nunito-light.woff') format('woff'),
        url('../fonts/nunito-light.ttf') format('truetype'),
        url('../fonts/nunito-light.svg#nunitolight') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 300;
    font-style: italic;
    src: url('../fonts/nunito-lightitalic.eot');
    src: url('../fonts/nunito-lightitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-lightitalic.woff2') format('woff2'),
        url('../fonts/nunito-lightitalic.woff') format('woff'),
        url('../fonts/nunito-lightitalic.ttf') format('truetype'),
        url('../fonts/nunito-lightitalic.svg#nunitolight_italic') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/nunito-regular.eot');
    src: url('../fonts/nunito-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-regular.woff2') format('woff2'),
        url('../fonts/nunito-regular.woff') format('woff'),
        url('../fonts/nunito-regular.ttf') format('truetype'),
        url('../fonts/nunito-regular.svg#nunitoregular') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 400;
    font-style: italic;
    src: url('../fonts/nunito-italic.eot');
    src: url('../fonts/nunito-italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-italic.woff2') format('woff2'),
        url('../fonts/nunito-italic.woff') format('woff'),
        url('../fonts/nunito-italic.ttf') format('truetype'),
        url('../fonts/nunito-italic.svg#nunitoitalic') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/nunito-semibold.eot');
    src: url('../fonts/nunito-semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-semibold.woff2') format('woff2'),
        url('../fonts/nunito-semibold.woff') format('woff'),
        url('../fonts/nunito-semibold.ttf') format('truetype'),
        url('../fonts/nunito-semibold.svg#nunitosemibold') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 600;
    font-style: italic;
    src: url('../fonts/nunito-semibolditalic.eot');
    src: url('../fonts/nunito-semibolditalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-semibolditalic.woff2') format('woff2'),
        url('../fonts/nunito-semibolditalic.woff') format('woff'),
        url('../fonts/nunito-semibolditalic.ttf') format('truetype'),
        url('../fonts/nunito-semibolditalic.svg#nunitosemibold_italic') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/nunito-bold.eot');
    src: url('../fonts/nunito-bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-bold.woff2') format('woff2'),
        url('../fonts/nunito-bold.woff') format('woff'),
        url('../fonts/nunito-bold.ttf') format('truetype'),
        url('../fonts/nunito-bold.svg#nunitobold') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 700;
    font-style: italic;
    src: url('../fonts/nunito-bolditalic.eot');
    src: url('../fonts/nunito-bolditalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-bolditalic.woff2') format('woff2'),
        url('../fonts/nunito-bolditalic.woff') format('woff'),
        url('../fonts/nunito-bolditalic.ttf') format('truetype'),
        url('../fonts/nunito-bolditalic.svg#nunitobold_italic') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 800;
    font-style: normal;
    src: url('../fonts/nunito-extrabold.eot');
    src: url('../fonts/nunito-extrabold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-extrabold.woff2') format('woff2'),
        url('../fonts/nunito-extrabold.woff') format('woff'),
        url('../fonts/nunito-extrabold.ttf') format('truetype'),
        url('../fonts/nunito-extrabold.svg#nunitoextrabold') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 800;
    font-style: italic;
    src: url('../fonts/nunito-extrabolditalic.eot');
    src: url('../fonts/nunito-extrabolditalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-extrabolditalic.woff2') format('woff2'),
        url('../fonts/nunito-extrabolditalic.woff') format('woff'),
        url('../fonts/nunito-extrabolditalic.ttf') format('truetype'),
        url('../fonts/nunito-extrabolditalic.svg#nunitoextrabold_italic') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/nunito-black.eot');
    src: url('../fonts/nunito-black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-black.woff2') format('woff2'),
        url('../fonts/nunito-black.woff') format('woff'),
        url('../fonts/nunito-black.ttf') format('truetype'),
        url('../fonts/nunito-black.svg#nunitoblack') format('svg');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 900;
    font-style: italic;
    src: url('../fonts/nunito-blackitalic.eot');
    src: url('../fonts/nunito-blackitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nunito-blackitalic.woff2') format('woff2'),
        url('../fonts/nunito-blackitalic.woff') format('woff'),
        url('../fonts/nunito-blackitalic.ttf') format('truetype'),
        url('../fonts/nunito-blackitalic.svg#nunitoblack_italic') format('svg');
}