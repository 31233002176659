@import "./stylecss/fonts.css";
/*
-----------------------------------------------------
	GENERAL
-----------------------------------------------------
*/
.row-divider>div:not(:first-child) {
	border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.row-divider-light>div:not(:first-child) {
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.text-gray {
	color: var(--gray);
}

.text-xs {
	font-size: .75rem;
}

.text-sm {
	font-size: .875rem;
}

.text-base {
	font-size: 1rem;
}

.text-lg {
	font-size: 1.125rem;
}

.text-xl {
	font-size: 1.25rem;
}

.text-2xl {
	font-size: 1.5rem;
}

.text-3xl {
	font-size: 1.875rem;
}

.text-4xl {
	font-size: 2.25rem;
}

.text-5xl {
	font-size: 3rem;
}

.text-6xl {
	font-size: 3.75rem;
}

.font-thin {
	font-weight: 100;
}

.font-extralight {
	font-weight: 200;
}

.font-light {
	font-weight: 300;
}

.font-normal {
	font-weight: 400;
}

.font-medium {
	font-weight: 500;
}

.font-semibold {
	font-weight: 600;
}

.font-bold {
	font-weight: 700;
}

.font-extrabold {
	font-weight: 800;
}

.font-black {
	font-weight: 900;
}

.text-underline {
	text-decoration: underline;
}

.line-through {
	text-decoration: line-through;
}

.no-underline {
	text-decoration: none;
}

.rounded-full {
	border-radius: 9999px;
}

.btn {
	text-transform: uppercase;
}

.btn-xs {
	font-size: .725rem;
	padding: .25rem .5rem;
}

.btn-sm {
	font-size: .85rem;
	padding: .5rem .85rem;
}

.btn-lg {
	font-size: 1.25rem;
	padding: 1rem 1.5rem;
}

.btn-white {
	color: var(--dark);
	background-color: #fff;
	border-color: #fff;
}

.btn-white:hover {
	color: var(--dark);
	background-color: #e2e6ea;
	border-color: #e2e6ea;
}

.btn-white:focus,
.btn-white.focus {
	color: #212529;
	background-color: #e2e6ea;
	border-color: #e2e6ea;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show>.btn-white.dropdown-toggle {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.bg-primary-light {
	background-color: #e9edfb;
}

/*
-----------------------------------------------------
	HEADER
-----------------------------------------------------
*/
.header {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 10;
}

.navbar-brand {
	max-width: 200px;
}

.navbar-light .navbar-nav .nav-link {
	font-weight: 600;
}

.banner-wrapper {
	position: relative;
	z-index: 2;
	background: url("images/background/hero-banner.jpg") no-repeat 0 0/cover;
}

.banner-wrapper .carousel-control-prev,
.banner-wrapper .carousel-control-next {
	height: 4rem;
	width: 4rem;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	bottom: 10%;
	left: 1rem;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.45)
}

.banner-wrapper .carousel-control-next {
	right: 1rem;
	left: auto;
}

.banner-wrapper .carousel-control-prev-icon,
.banner-wrapper .carousel-control-next-icon {
	background: none;
}

.clients-box img {
	filter: grayscale(1);
	opacity: .5;
	transition: all .25s ease-in-out 0s;
}

.clients-box:hover img {
	filter: grayscale(0);
	opacity: 1;
}

.promotion-wrap {
	position: relative;
	z-index: 2;
}

.promotion-left {
	border-radius: 0 25rem 25rem 0;

}

.promotion-left::before {
	content: "";
	background: url("images/assets/logo-cutout.png") no-repeat left center;
	position: absolute;
	left: -50%;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.promotion-right {
	border-radius: 25rem 0 0 25rem;
	position: relative;
	z-index: 2;
}

.btn-fancy {
	position: relative;
	z-index: 2;
	padding: .5rem 1rem;
	margin-top: 1.5rem;
	color: var(--dark);
	font-weight: 600;
}

.btn-fancy:before {
	content: "";
	position: absolute;
	left: 0;
	width: 50px;
	height: 50px;
	background-color: inherit;
	border-radius: 5rem;
	top: 50%;
	z-index: -1;
	transform: translateY(-50%);
	transition: all 0.25s ease-in-out 0s;
}

.btn-fancy:hover {
	text-decoration: none;
	color: var(--white);
}

.btn-fancy:hover:before {
	width: 120%;
}

.btn-fancy-primay:before {
	background-color: var(--primary);
}

.btn-fancy-danger:before {
	background-color: var(--danger);
}

.list-style-check {
	padding: 2rem 0 0 0;
	margin: 0;
	list-style: none;
}

.list-style-check>li {
	padding: 0 0 0 2.5rem;
	margin-bottom: 1.5rem;
	position: relative;
	font-size: 1.125rem;
}

.list-style-check>li:before {
	content: "\f00c";
	position: absolute;
	left: 0;
	top: 0;
	font-size: 1.25rem;
	font-family: 'Line Awesome Free';
	font-weight: 900;
}

.border-radius-lg {
	border-radius: 2.5rem;
}

.circle-icon {
	height: 80px;
	width: 80px;
	border-radius: 50%;
}

.app-summary-odd,
.app-summary-even {
	overflow-x: hidden;
}

.app-info {
	position: relative;
	z-index: 2;
}

.app-info:before {
	content: "";
	position: absolute;
	background-color: inherit;
	left: -100%;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}



.app-summary-even .app-info:before {
	right: -100%;
	left: auto;

}

.proccess-card {
	border-radius: 50rem;
	width: 190px;
	height: 190px;
	padding: 2rem;
	position: relative;
	margin: 0 auto;
}

.proccess-col:nth-child(3n+1) .proccess-card {
	background-color: #fbe9e9;
}

.proccess-col:nth-child(3n+2) .proccess-card {
	background-color: #e9edfb;
}

.proccess-col:nth-child(3n+3) .proccess-card {
	background-color: #ecfbe9;
}



.proccess-col:not(:last-child) .proccess-card:after {
	content: "";
	position: absolute;
	background: url("images/assets/arrow.png") no-repeat 0 0;
	height: 90px;
	width: 140px;
	left: 50%;
	bottom: -50%;
	transform: scaleY(-1);
}

.proccess-col:nth-child(2n+2) .proccess-card:after {
	bottom: auto;
	top: -50%;
	transform: rotate(0) scaleY(1);
}

/*
-----------------------------------------------------
	FOOTER
-----------------------------------------------------
*/
.footer {
	font-size: .875rem;
	margin-top: 8rem;
}

.footer-conatcts-wrap {
	position: relative;
}



.footer-link-widgets a {
	color: var(--light);
	opacity: .5;
	padding: .25rem 0;
	display: inline-block;
}

.footer-link-widgets a:hover {
	text-decoration: none;
	color: var(--white);
	opacity: 1;
}

.social-networks a {
	background-color: var(--light);
	color: var(--dark);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-left: .5rem;
	line-height: 30px;
	border-radius: 50%;
}

.social-networks a:hover {
	background-color: var(--white);
	color: var(--primary);
}

.copyright {
	border-top: 1px solid rgba(255, 255, 255, .1);
	padding-top: 1rem;
	margin-top: 1rem;
	font-size: .875rem;
}

/*
-----------------------------------------------------
	TESTIMONIALS
-----------------------------------------------------
*/
.testimonials-media {
	position: absolute;
	bottom: -50px;
}

.testimonials-picture {
	height: 100px;
	width: 100px;
	border-radius: 50%;
	overflow: hidden;
}

.testimonials-picture img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.nav-tabs.custom-tabs {
	padding: 0 1.25rem;
	margin-bottom: -1px
}

.nav-tabs.custom-tabs .nav-link {
	margin-bottom: -1px;
	border: 0;
	border-bottom: 4px solid transparent;
	border-radius: 0;
	color: var(--gray-dark);
	padding: 1.25rem 1.5rem;
	font-weight: 600;
	font-size: 1.25rem;
	background-color: transparent;
}

.nav-tabs.custom-tabs .nav-link.active,
.nav-tabs.custom-tabs .nav-item.show .nav-link {
	color: var(--primary);
	background-color: transparent;
	border-color: var(--primary);
}

#appsTabContent .owl-carousel .item img {
	border: 8px solid var(--primary);
	border-radius: 15px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.category-card-icon img {
	max-width: 80px;
}

.technology-block {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background: linear-gradient(145deg, #e9edfb, #d1daf8);
	box-shadow: 10px 10px 40px #a8a9ca,
		-20px -20px 60px #ffffff;
}

.technology-block img {
	max-width: 60px;
	fill: var(--dark);
}
.enquiryForm-card{
	border-radius: 2.5rem;
}
.enquiryForm-card label{
	color: var(--white);
}
.enquiryForm-card .form-control {
	height: calc(2em + 0.75rem + 2px);
	border: 0;
	border-radius: 2.5rem;
}
.enquiryForm-card  textarea.form-control{
	min-height: 120px;
	border-radius: 1.85rem;
}


/*
--------------------------------------
 MEDIA QUERIRES
--------------------------------------
*/
@media only screen and (min-width: 768px) {
	body {
		padding-top: 0 !important;
	}

	.promotion-wrap:before,
	.promotion-wrap:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 25%;
		height: 100%;
		z-index: -1;
		background-color: var(--danger);

	}

	.promotion-wrap:after {
		left: auto;
		right: 0;
		width: 70%;
		height: auto;
		top: -4rem;
		bottom: -4rem;
		background-color: var(--primary);
		border-radius: 25rem 0 0 25rem;
	}

	.app-info {
		position: relative;
		border-radius: 0 50rem 50rem 0;
		padding-right: 10rem;
	}

	.app-summary-even .app-info {
		padding-left: 10rem;
		padding-right: 0;
		border-radius: 50rem 0 0 50rem;
	}

	.proccess-col:nth-child(2n+2) .proccess-card {
		margin-top: 5rem;
	}

	.footer-contacts-module {
		position: absolute;
		width: 100%;
		top: -8rem;
		border-radius: 50rem;
		padding: 2rem 5rem;
	}

}

@media only screen and (min-width: 992px) {
	.navbar-nav .dropdown {
		-ms-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform-style: preserve-3d;
		perspective: 1000px;
		-webkit-perspective: 1000px;
		z-index: 98;
	}

	.dropdown-menu {
		display: block;
		background-color: var(--white);
		border: 0;
		margin: 0;
		padding: 0;
		font-size: .9rem;
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		min-width: 100%;
		min-width: 200px;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
		-webkit-transform-origin: top center;
		transform-origin: top center;
		-webkit-transform: scaleY(0) translateZ(100px);
		transform: scaleY(0) translateZ(100px);
		-webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
		transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
		transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
		transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
	}

	.dropdown:hover .dropdown-menu {
		opacity: 1;
		visibility: visible;
		-webkit-transform: scaleY(1) translateZ(0px);
		transform: scaleY(1) translateZ(0px);
		/* -webkit-animation: slide-bottom 0.25s ease-in-out both;
		animation: slide-bottom 0.75s ease-in-out both; */
	}

	/* @-webkit-keyframes slide-bottom {
		0% {
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}

		100% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}
	}

	@keyframes slide-bottom {
		0% {
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}

		100% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}
	} */

	.navigation-wrapper .dropdown-item {
		padding: 0;
	}

	.navigation-wrapper .navbar-nav .dropdown-item .nav-link {
		color: var(--dark);
		padding: 0.8rem 1rem;
		display: block;
		font-weight: 600;
		position: relative;
		z-index: 2;
	}
	.navigation-wrapper .navbar-nav .dropdown-item .nav-link:after {
		content: "";
		position: absolute;
		width: 3px;
		top: 0px;
		background-color: var(--primary);
		left: 0;
		transition: all .15s ease-in-out 0s;
		z-index: -1;
		bottom: -1px;
	}

	.navigation-wrapper .navbar-nav .dropdown-item a:hover {
		color: var(--white);
		background-color: var(--white);
		text-decoration: none;
		right: 0;
	}
	.navigation-wrapper .navbar-nav .dropdown-item .nav-link:hover:after {
		width: 100%;
	}
	.enquiryNow-wrap{
		padding-bottom: 10rem;
	}
	.enquiryForm-card{
		position:absolute;
		width: 100%;
		top: -220px;
		left: -15px;
		border-radius: 2.5rem;
	}
}

@media only screen and (min-width:1200px) {}

@media only screen and (min-width: 1024px) {}

@media only screen and (min-width: 768px) and (max-width:1024px) {}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {}

@media only screen and (min-width: 1024px) and (max-width:1199px) {}

@media only screen and (min-width: 1025px) and (max-width:1360px) {}

@media only screen and (min-width:1200px) and (max-width:1280px) {}

@media only screen and (min-width:1300px) and (max-width:1366px) {}

@media only screen and (min-width: 992px) and (max-width: 1024px) {}

@media only screen and (max-width: 992px) {
	#mainNavigation.navbar-collapse,
	#mainNavigation.collapse:not(.show) {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 300;
		height: 100vh;
		overflow: auto;
		background-color: var(--primary);
		width: 80%;
		max-width: 220px;
		padding: 1rem;
		display: block;
		visibility: hidden;
		transform: translate(-100%, 0);
		-webkit-transform: translate(-100%, 0);
		transition: all 0.35s ease-in-out 0s;
		-webkit-transition: all 0.35s ease-in-out 0s;
		-moz-transition: all 0.35s ease-in-out 0s;
		-ms-transition: all 0.35s ease-in-out 0s;
		-o-transition: all 0.35s ease-in-out 0s;
	}

	#mainNavigation.show {
		transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		visibility: visible;
	}

	.navbar-light .navbar-nav .nav-link {
		color: var(--white);
	}

	.navbar-light .navbar-nav .nav-link:hover,
	.navbar-light .navbar-nav .nav-link:focus {
		color: var(--white);
	}

	.navbar-light .navbar-nav .show>.nav-link,
	.navbar-light .navbar-nav .active>.nav-link,
	.navbar-light .navbar-nav .nav-link.show,
	.navbar-light .navbar-nav .nav-link.active {
		color: var(--white);
	}

	.dropdown-menu {
		background-color: rgba(255, 255, 255, .15);
		border: 0;
	}
}

@media only screen and (max-width: 767px) {
	body{
	padding-top: 60px;
}
	html {
		font-size: 15px;
	}

	.header {
		position: fixed !important;
		left: 0;
		top: 0;
		width: 100%;
		box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
		z-index: 10;
		background-color: var(--light);
	}

	.navbar-brand {
		max-width: 120px;
	}

	.promotion-wrap {

		overflow-x: hidden;
	}

	.promotion-left::before {
		background-color: var(--danger);
	}

	.promotion-right::before {
		content: "";
		position: absolute;
		background-color: var(--primary);
		right: -50%;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	.app-info::before,
	.app-summary-even .app-info:before {
		left: -15px;
		right: -15px;
		width: auto;
	}

	.btn+.btn {
		margin-top: 1rem;
	}

	.proccess-col:not(:last-child) .proccess-card::after {
		left: -45%;
		bottom: -20%;
		top: auto;
		transform: rotate(64deg) scaleY(-1);
	}

	.footer {
		margin-top: 0;
	}

	.footer-contacts-module {
		padding: 2rem
	}
	.circle-icon {
		height: 60px;
		width: 60px;
	  }
	  .footer-conatcts-wrap {
		padding-top: 1rem;
	  }
	  .footer-contacts-module {
		padding: 2rem;
	  }
}

@media only screen and (max-width:375px) {}

@media only screen and (min-width:590px) {}

@media only screen and (min-width: 620px) {}